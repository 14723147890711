import { gql } from '@apollo/client'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Autocomplete,
  Button,
  CircularProgress,
  FilterOptionsState,
  Menu,
  MenuItem,
  PopoverOrigin,
  Skeleton,
  TextField,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import _ from 'lodash'
import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'
import {
  ContractListContractStatusFilter,
  ContractListSortCriteria,
  PaginatedListSortOrder,
  ProjectOnboardingFormType,
  pdfTypes,
} from 'siteline-common-all'
import {
  SitelineText,
  colors,
  generatePdfWithMetadata,
  makeStylesFast,
  useDebouncedSearch,
} from 'siteline-common-web'
import EmptySearchStateImage from '../../../assets/images/emptySearchState.svg'
import { ArchivedChip } from '../../../common/components/ArchivedChip'
import { CopyProjectDropdownOption } from '../../../common/components/CopyProjectDropdownOption'
import { PdfPreviewDialog } from '../../../common/components/Pdf/PdfPreviewDialog'
import { SameGcChip } from '../../../common/components/SameGcChip'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import * as fragments from '../../../common/graphql/Fragments'
import {
  useGetContractForFormsQuery,
  useGetContractsForFormsQuery,
} from '../../../common/graphql/apollo-operations'
import {
  ContractForForms,
  ContractOnboardingFormType,
  deriveFormSelectionStatusFromContract,
} from '../../../common/util/ProjectOnboarding'
import { IncludeChangeOrderLogInPayAppToggle } from './IncludeChangeOrderLogInPayAppToggle'

const useStyles = makeStylesFast((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  previewDropdown: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& .previewMenuButton': {
      marginLeft: theme.spacing(0.5),
      '& .icon': {
        color: colors.grey50,
      },
    },
  },
  autocomplete: {
    position: 'relative',
    '& .endIcon': {
      position: 'absolute',
      top: 0,
      bottom: 0,
      // Position to the left of the close icon
      right: 64,
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    '& input': {
      // Leave room for the "Same GC" chip at the end. Overrides default input styling
      // from MUI TextField.
      paddingRight: '140px !important',
    },
  },
  loadingIcon: {
    marginRight: theme.spacing(1),
  },
  loadingRow: {
    margin: theme.spacing(0, 2),
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& img': {
      width: 90,
      marginBottom: theme.spacing(2),
    },
  },
}))

gql`
  query getContractsForForms($input: GetPaginatedContractsInput!) {
    paginatedContracts(input: $input) {
      cursor
      hasNext
      contracts {
        id
        billingType
        internalProjectNumber
        status
        payAppRequirementGroups {
          id
          order
          payAppRequirements {
            id
          }
        }
        lienWaiverTemplates {
          id
        }
        lowerTierLienWaiverTemplates {
          id
        }
        changeOrderRequestTemplate {
          id
          versions {
            id
            versionNumber
          }
        }
        changeOrderLogTemplate {
          id
          versions {
            id
            versionNumber
          }
        }
        onboardedStatus {
          ...OnboardedProjectContractStatusProperties
        }
        company {
          id
        }
        project {
          id
          name
          projectNumber
          generalContractor {
            companyName
            company {
              id
              name
            }
          }
        }
      }
    }
  }
  ${fragments.onboardedProjectContractStatus}
`

gql`
  query getContractForForms($input: GetContractByProjectIdInput!) {
    contractByProjectId(input: $input) {
      id
      billingType
      project {
        id
        generalContractor {
          company {
            id
          }
        }
      }
    }
  }
`

interface UseFormsPdfParams {
  contract?: ContractForForms
  formsType: ContractOnboardingFormType | null
}

const useFormsPdf = ({ contract, formsType }: UseFormsPdfParams) => {
  const [pdf, setPdf] = useState<Blob | null>(null)
  const [metadata, setMetadata] = useState<pdfTypes.PageMetadata[]>([])

  const { companyId } = useCompanyContext()

  // Generate the PDF to preview all the default forms
  useEffect(() => {
    async function generateFormsPdf() {
      setPdf(null)
      if (!contract?.id || formsType === null) {
        setMetadata([])
        return
      }
      let payloadPackage: pdfTypes.PayloadPackage
      switch (formsType) {
        case ProjectOnboardingFormType.PAY_APP: {
          payloadPackage = {
            type: 'projectFormsPreview',
            contractId: contract.id,
          }
          break
        }
        case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST: {
          // This will always exist, or the project wouldn't have appeared in the dialog
          const formTemplateVersion = _.maxBy(
            contract.changeOrderRequestTemplate?.versions ?? [],
            (version) => version.versionNumber
          )
          payloadPackage = {
            type: 'templatesPreview',
            formTemplateVersionIds: formTemplateVersion ? [formTemplateVersion.id] : [],
            contractId: contract.id,
          }
          break
        }
        case ProjectOnboardingFormType.CHANGE_ORDER_LOG: {
          const formTemplateVersion = _.maxBy(
            contract.changeOrderLogTemplate?.versions ?? [],
            (version) => version.versionNumber
          )
          payloadPackage = {
            type: 'templatesPreview',
            formTemplateVersionIds: formTemplateVersion ? [formTemplateVersion.id] : [],
            contractId: contract.id,
          }
          break
        }
        case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
        case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER: {
          payloadPackage = {
            type: 'projectLienWaiversPreview',
            lienWaiverType:
              formsType === ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER ? 'primary' : 'vendors',
            contractId: contract.id,
          }
          break
        }
      }
      const formsPdf = await generatePdfWithMetadata(payloadPackage)
      setPdf(formsPdf.blob)
      setMetadata(formsPdf.metadata)
    }

    generateFormsPdf()
  }, [
    contract?.id,
    contract?.changeOrderRequestTemplate,
    companyId,
    formsType,
    contract?.changeOrderLogTemplate?.versions,
  ])

  return { pdf, metadata }
}

const i18nBase = 'projects.subcontractors.settings.forms'

const previewMenuTransformOrigin: PopoverOrigin = { horizontal: 'left', vertical: 'top' }
const previewMenuAnchorOrigin: PopoverOrigin = { horizontal: 'left', vertical: 'bottom' }

const getContractInputLabel = (contract: ContractForForms) => {
  const projectNumber = contract.internalProjectNumber ?? contract.project.projectNumber
  return `${contract.project.name} ${projectNumber} ${
    contract.project.generalContractor ? `(${contract.project.generalContractor.company.name})` : ''
  }`
}

const canCopyFormsFromProject = (
  formType: ContractOnboardingFormType,
  contract: ContractForForms
) => {
  const { hasMadeSelection, isProcessingForms, hasFormTemplates } =
    deriveFormSelectionStatusFromContract(formType, contract)
  if (!hasMadeSelection) {
    return false
  }
  if (!isProcessingForms && !hasFormTemplates) {
    return false
  }
  return true
}

interface CopyProjectFormsDialogProps {
  open: boolean
  onClose: (fromButton: boolean) => void
  cancelLabel?: string
  onConfirm: (
    formTypes: ContractOnboardingFormType[],
    contractId: string,
    includeChangeOrderLogOnPayApps?: boolean
  ) => void
  formTypes: ContractOnboardingFormType[]
  submitting?: boolean
  /** Pass in undefined if this dialog is NOT for change order log forms */
  includeChangeOrderLogInPayAppPackage: boolean | undefined
}

/** Dialog for selecting a project and previewing its forms */
export function CopyProjectFormsDialog({
  open,
  onClose,
  cancelLabel,
  onConfirm,
  formTypes,
  submitting,
  includeChangeOrderLogInPayAppPackage,
}: CopyProjectFormsDialogProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const { contract } = useProjectContext()
  const { companyId } = useCompanyContext()
  const { search, debouncedSearch, onSearch } = useDebouncedSearch()

  const input = useMemo(
    () => ({
      companyId,
      includeAllCompanyContracts: true,
      contractStatus: ContractListContractStatusFilter.ALL,
      search: debouncedSearch,
      sort: [
        {
          criteria: ContractListSortCriteria.SAME_GC,
          order: PaginatedListSortOrder.ASC,
          compareWithContractId: contract?.id,
        },
        { criteria: ContractListSortCriteria.CONTRACT_STATUS, order: PaginatedListSortOrder.ASC },
      ],
    }),
    [contract?.id, debouncedSearch, companyId]
  )

  const {
    data,
    fetchMore,
    loading: loadingContracts,
  } = useGetContractsForFormsQuery({
    variables: { input },
    skip: !contract?.id,
  })

  const { data: contractData, loading: loadingContract } = useGetContractForFormsQuery({
    variables: {
      input: {
        projectId: contract?.project.id ?? '',
        companyId,
      },
    },
    skip: !contract,
  })

  const loading = loadingContracts || loadingContract
  const cursor = data?.paginatedContracts.cursor
  const hasNext = data?.paginatedContracts.hasNext ?? false

  const loadMore = useCallback(() => {
    fetchMore({ variables: { input: { cursor, ...input } } })
  }, [cursor, fetchMore, input])

  const currentContract = useMemo(
    () => contractData && contractData.contractByProjectId,
    [contractData]
  )

  const initialFormType = useMemo(
    () => _.get(formTypes, 0, ProjectOnboardingFormType.PAY_APP),
    [formTypes]
  )

  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLSpanElement>(null)
  const [selectedContract, setSelectedContract] = useState<ContractForForms>()
  const [formPreviewType, setFormPreviewType] =
    useState<ContractOnboardingFormType>(initialFormType)
  const [includeCOLogOnPayApps, setIncludeCOLogOnPayApps] = useState<boolean>(
    !!includeChangeOrderLogInPayAppPackage
  )

  const formTypesSet = useMemo(() => new Set(formTypes), [formTypes])

  const shouldDisplayFormTypeDropdown = formTypes.length > 1

  const shouldDisplayIncludeCoLogToggle =
    formTypesSet.has(ProjectOnboardingFormType.CHANGE_ORDER_LOG) &&
    includeChangeOrderLogInPayAppPackage !== undefined

  const handleResetDialog = useCallback(() => {
    onSearch('')
    setFormPreviewType(initialFormType)
    setSelectedContract(undefined)
    setIncludeCOLogOnPayApps(!!includeChangeOrderLogInPayAppPackage)
  }, [includeChangeOrderLogInPayAppPackage, initialFormType, onSearch])

  const contracts = useMemo(() => {
    if (!data || !currentContract) {
      return []
    }
    const filtered =
      // Only include projects that have the form type(s) we're looking for and match the billing type
      _.filter(data.paginatedContracts.contracts, (potentialContract) => {
        // Exclude the contract being onboarded
        if (potentialContract.id === currentContract.id) {
          return false
        }

        // If we're copying multiple form type templates (i.e., user selected bulk copy option),
        // be sure to only include projects that 1) have pay app templates to copy, and 2) have the same billing type.
        // This logic is the same for copying pay app form templates directly.
        const canCopyPayAppFormsFromProject =
          canCopyFormsFromProject(ProjectOnboardingFormType.PAY_APP, potentialContract) &&
          potentialContract.billingType === currentContract.billingType

        if (formTypes.length > 1) {
          return canCopyPayAppFormsFromProject
        }

        switch (initialFormType) {
          case ProjectOnboardingFormType.PAY_APP:
            return canCopyPayAppFormsFromProject
          case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
            return canCopyFormsFromProject(
              ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER,
              potentialContract
            )
          case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
            return canCopyFormsFromProject(
              ProjectOnboardingFormType.VENDOR_LIEN_WAIVER,
              potentialContract
            )
          case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
            return canCopyFormsFromProject(
              ProjectOnboardingFormType.CHANGE_ORDER_REQUEST,
              potentialContract
            )
          case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
            return canCopyFormsFromProject(
              ProjectOnboardingFormType.CHANGE_ORDER_LOG,
              potentialContract
            )
        }
      })
    return filtered
  }, [data, currentContract, formTypes.length, initialFormType])

  const canPreviewPayApp =
    open &&
    formTypesSet.has(ProjectOnboardingFormType.PAY_APP) &&
    !!selectedContract &&
    selectedContract.payAppRequirementGroups.length > 0 &&
    selectedContract.onboardedStatus.onboardedPayAppForms

  const canPreviewPrimaryLienWaivers =
    open &&
    formTypesSet.has(ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER) &&
    !!selectedContract?.lienWaiverTemplates &&
    !!selectedContract.onboardedStatus.onboardedPrimaryLienWaiverForms

  const canPreviewVendorLienWaivers =
    open &&
    formTypesSet.has(ProjectOnboardingFormType.VENDOR_LIEN_WAIVER) &&
    !!selectedContract?.lowerTierLienWaiverTemplates &&
    !!selectedContract.onboardedStatus.onboardedVendorLienWaiverForms

  const canPreviewChangeOrderRequest =
    open &&
    formTypesSet.has(ProjectOnboardingFormType.CHANGE_ORDER_REQUEST) &&
    !!selectedContract?.changeOrderRequestTemplate &&
    !!selectedContract.onboardedStatus.onboardedChangeOrderRequestForms
  const canPreviewChangeOrderLog =
    open &&
    formTypesSet.has(ProjectOnboardingFormType.CHANGE_ORDER_LOG) &&
    !!selectedContract?.changeOrderLogTemplate &&
    !!selectedContract.onboardedStatus.onboardedChangeOrderLogForms

  const { pdf: payAppPdf, metadata: payAppMetadata } = useFormsPdf({
    contract: selectedContract,
    formsType: canPreviewPayApp ? ProjectOnboardingFormType.PAY_APP : null,
  })

  const { pdf: changeOrderPdf, metadata: changeOrderMetadata } = useFormsPdf({
    contract: selectedContract,
    formsType: canPreviewChangeOrderRequest ? ProjectOnboardingFormType.CHANGE_ORDER_REQUEST : null,
  })

  const { pdf: changeOrderLogPdf, metadata: changeOrderLogMetadata } = useFormsPdf({
    contract: selectedContract,
    formsType: canPreviewChangeOrderLog ? ProjectOnboardingFormType.CHANGE_ORDER_LOG : null,
  })

  const { pdf: primaryLienWaiverPdf, metadata: primaryLienWaiverMetadata } = useFormsPdf({
    contract: selectedContract,
    formsType: canPreviewPrimaryLienWaivers ? ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER : null,
  })

  const { pdf: vendorLienWaiverPdf, metadata: vendorLienWaiverMetadata } = useFormsPdf({
    contract: selectedContract,
    formsType: canPreviewVendorLienWaivers ? ProjectOnboardingFormType.VENDOR_LIEN_WAIVER : null,
  })

  const { pdf, metadata, isProcessingForms } = useMemo(() => {
    switch (formPreviewType) {
      case ProjectOnboardingFormType.PAY_APP:
        return {
          pdf: payAppPdf,
          metadata: payAppMetadata,
          isProcessingForms:
            !!selectedContract &&
            deriveFormSelectionStatusFromContract(formPreviewType, selectedContract)
              .isProcessingForms,
        }
      case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
        return {
          pdf: changeOrderPdf,
          metadata: changeOrderMetadata,
          isProcessingForms:
            !!selectedContract &&
            deriveFormSelectionStatusFromContract(formPreviewType, selectedContract)
              .isProcessingForms,
        }
      case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
        return {
          pdf: changeOrderLogPdf,
          metadata: changeOrderLogMetadata,
          isProcessingForms:
            !!selectedContract &&
            deriveFormSelectionStatusFromContract(formPreviewType, selectedContract)
              .isProcessingForms,
        }
      case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
        return {
          pdf: primaryLienWaiverPdf,
          metadata: primaryLienWaiverMetadata,
          isProcessingForms:
            !!selectedContract &&
            deriveFormSelectionStatusFromContract(formPreviewType, selectedContract)
              .isProcessingForms,
        }
      case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
        return {
          pdf: vendorLienWaiverPdf,
          metadata: vendorLienWaiverMetadata,
          isProcessingForms:
            !!selectedContract &&
            deriveFormSelectionStatusFromContract(formPreviewType, selectedContract)
              .isProcessingForms,
        }
    }
  }, [
    formPreviewType,
    payAppPdf,
    payAppMetadata,
    selectedContract,
    changeOrderPdf,
    changeOrderMetadata,
    changeOrderLogPdf,
    changeOrderLogMetadata,
    primaryLienWaiverPdf,
    primaryLienWaiverMetadata,
    vendorLienWaiverPdf,
    vendorLienWaiverMetadata,
  ])

  // If a user selects bulk copy forms from project, we should only allow them to preview forms
  // that are available under the selected project (we allow them to select any project that
  // has pay app forms)
  const filteredFormPreviewOptions = useMemo(() => {
    if (formTypes.length === 1) {
      return formTypes
    }
    if (!selectedContract) {
      return []
    }
    return formTypes.filter((formTypeOption) => {
      switch (formTypeOption) {
        case ProjectOnboardingFormType.PAY_APP:
          return canCopyFormsFromProject(formTypeOption, selectedContract)
        case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
          return canCopyFormsFromProject(formTypeOption, selectedContract)
        case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
          return canCopyFormsFromProject(formTypeOption, selectedContract)
        case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
          return canCopyFormsFromProject(formTypeOption, selectedContract)
        case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
          return canCopyFormsFromProject(formTypeOption, selectedContract)
      }
    })
  }, [formTypes, selectedContract])

  // If the selected preview form type changes, then the user selects a project that doesn't
  // have the selected form type, revert formPreviewType to the initial form type
  useEffect(() => {
    if (formTypes.length > 1 && !filteredFormPreviewOptions.includes(formPreviewType)) {
      setFormPreviewType(initialFormType)
    }
  }, [filteredFormPreviewOptions, formPreviewType, formTypes.length, initialFormType])

  const formTypeToPreviewMenuLabel = useCallback(
    (formTypeOption: ContractOnboardingFormType) => {
      switch (formTypeOption) {
        case ProjectOnboardingFormType.PAY_APP:
          return t(`${i18nBase}.pay_app_forms`)
        case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
          return t(`${i18nBase}.change_order_forms`)
        case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
          return t(`${i18nBase}.change_order_log_forms`)
        case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
          return t(`${i18nBase}.primary_lien_waiver_forms`)
        case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
          return t(`${i18nBase}.vendor_lien_waiver_forms`)
      }
    },
    [t]
  )

  const previewMenuLabel = useMemo(
    () => formTypeToPreviewMenuLabel(formPreviewType),
    [formPreviewType, formTypeToPreviewMenuLabel]
  )

  const handleOpenPreviewMenu = useCallback((event: MouseEvent<HTMLSpanElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }, [])

  const handleClosePreviewMenu = useCallback(() => {
    setMenuAnchorEl(null)
  }, [])

  const handleChange = useCallback(
    (_event: ChangeEvent<unknown>, contractSelection: ContractForForms | null) => {
      if (formTypes.includes(ProjectOnboardingFormType.CHANGE_ORDER_LOG)) {
        const hasSelectedContract = contractSelection !== null
        const doesSelectedContractHaveCoLog = hasSelectedContract
          ? canCopyFormsFromProject(ProjectOnboardingFormType.CHANGE_ORDER_LOG, contractSelection)
          : false

        if (selectedContract === undefined && doesSelectedContractHaveCoLog) {
          setIncludeCOLogOnPayApps(true)
        }
        if (!doesSelectedContractHaveCoLog) {
          setIncludeCOLogOnPayApps(false)
        }
      }

      setSelectedContract(contractSelection ?? undefined)
    },
    [formTypes, selectedContract]
  )

  const handleSelectFormPreviewType = useCallback(
    (formTypeOption: ContractOnboardingFormType) => {
      setFormPreviewType(formTypeOption)
      handleClosePreviewMenu()
    },
    [handleClosePreviewMenu]
  )

  const noContractsMessage = useMemo(() => {
    if (formTypes.length > 1) {
      return t(`${i18nBase}.no_projects.multiple_form_types`)
    }
    switch (initialFormType) {
      case ProjectOnboardingFormType.PAY_APP:
        return t(`${i18nBase}.no_projects.pay_app`)
      case ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER:
        return t(`${i18nBase}.no_projects.primary_lien_waivers`)
      case ProjectOnboardingFormType.VENDOR_LIEN_WAIVER:
        return t(`${i18nBase}.no_projects.vendor_lien_waivers`)
      case ProjectOnboardingFormType.CHANGE_ORDER_REQUEST:
        return t(`${i18nBase}.no_projects.change_order_request`)
      case ProjectOnboardingFormType.CHANGE_ORDER_LOG:
        return t(`${i18nBase}.no_projects.change_order_log`)
    }
  }, [formTypes.length, initialFormType, t])

  const handleConfirm = useCallback(() => {
    if (!selectedContract || !open || submitting) {
      return
    }
    const includeChangeOrderLogOnPayApps =
      shouldDisplayIncludeCoLogToggle &&
      canCopyFormsFromProject(ProjectOnboardingFormType.CHANGE_ORDER_LOG, selectedContract)
        ? includeCOLogOnPayApps
        : undefined
    onConfirm(filteredFormPreviewOptions, selectedContract.id, includeChangeOrderLogOnPayApps)
  }, [
    filteredFormPreviewOptions,
    includeCOLogOnPayApps,
    onConfirm,
    open,
    selectedContract,
    shouldDisplayIncludeCoLogToggle,
    submitting,
  ])

  if (!search && contracts.length === 0) {
    return (
      <SitelineDialog
        open={open}
        onClose={onClose}
        cancelLabel={cancelLabel}
        title={t(`${i18nBase}.copy_forms_from_project`)}
        maxWidth="sm"
      >
        {loading && (
          <SitelineText
            variant="body1"
            color="grey50"
            startIcon={<CircularProgress size={16} className={classes.loadingIcon} />}
          >
            {t(`${i18nBase}.no_projects.loading`)}
          </SitelineText>
        )}
        {!loading && (
          <SitelineText variant="body1" color="grey50">
            {noContractsMessage}
          </SitelineText>
        )}
      </SitelineDialog>
    )
  }

  // If there's a search term in the input and either an active query or the debounce function
  // is in its delay phase, we show a "searching" message in the autocomplete dropdown
  const isSearching = _.isString(search) && (debouncedSearch !== search || loadingContracts)

  return (
    <PdfPreviewDialog
      open={open}
      onSubmit={handleConfirm}
      disableSubmit={!selectedContract || submitting}
      onClose={onClose}
      cancelLabel={cancelLabel}
      title={t(`${i18nBase}.copy_forms_from_project`)}
      submitLabel={t('common.actions.confirm')}
      file={pdf}
      metadata={metadata}
      submitting={submitting}
      isProcessingForms={isProcessingForms}
      fixedTopPosition={0}
      loading={!!selectedContract && !pdf}
      emptyState={
        <div className={classes.emptyState}>
          <img src={EmptySearchStateImage} alt="" />
          <SitelineText variant="body1" color="grey50">
            {t(`${i18nBase}.empty_state_copy_instructions`)}
          </SitelineText>
        </div>
      }
      onResetDialog={handleResetDialog}
      subscript={
        shouldDisplayIncludeCoLogToggle ? (
          <IncludeChangeOrderLogInPayAppToggle
            shouldInclude={includeCOLogOnPayApps}
            onChange={setIncludeCOLogOnPayApps}
            disabled={
              !selectedContract ||
              !canCopyFormsFromProject(ProjectOnboardingFormType.CHANGE_ORDER_LOG, selectedContract)
            }
            shouldShowDisabledTooltip={false}
          />
        ) : undefined
      }
    >
      <Autocomplete
        freeSolo={false}
        options={contracts}
        value={selectedContract}
        noOptionsText={isSearching ? t(`${i18nBase}.searching`) : undefined}
        isOptionEqualToValue={(option, value) => {
          return option.id === value.id
        }}
        onChange={handleChange}
        renderGroup={(params) => {
          return (
            <InfiniteScroll threshold={0} loadMore={loadMore} hasMore={hasNext} useWindow={false}>
              <div {...params} />
              {hasNext && (
                <div className={classes.loadingRow}>
                  <Skeleton variant="rectangular" width="70%" height={20} />
                </div>
              )}
            </InfiniteScroll>
          )
        }}
        // Use a single empty group to enable infinite scroll by wrapping the group element
        groupBy={() => ''}
        renderOption={(props, contract) => (
          <CopyProjectDropdownOption
            key={contract.id}
            inputProps={props}
            currentContract={currentContract}
            contractOption={contract}
          />
        )}
        getOptionLabel={getContractInputLabel}
        renderInput={(params) => (
          <div className={classes.autocomplete}>
            <TextField
              {...params}
              variant="outlined"
              type="label"
              placeholder={t(`${i18nBase}.search_placeholder`)}
              autoFocus
            />
            <div className="endIcon">
              <ArchivedChip contract={selectedContract} />
              <SameGcChip currentContract={currentContract} otherContract={selectedContract} />
            </div>
          </div>
        )}
        onInputChange={(ev, value) => onSearch(value)}
        filterOptions={(
          contracts: ContractForForms[],
          state: FilterOptionsState<ContractForForms>
        ) => {
          return contracts.filter((contract) =>
            getContractInputLabel(contract).toLowerCase().includes(state.inputValue.toLowerCase())
          )
        }}
      />
      {shouldDisplayFormTypeDropdown && (
        <div className={classes.previewDropdown}>
          <SitelineText variant="body1" color="grey70">
            {t(`${i18nBase}.preview`)}
          </SitelineText>
          <Button
            variant="text"
            onClick={handleOpenPreviewMenu}
            className="previewMenuButton"
            endIcon={
              filteredFormPreviewOptions.length > 0 ? (
                <ArrowDropDownIcon className="icon" fontSize="small" />
              ) : undefined
            }
            disabled={filteredFormPreviewOptions.length === 0}
          >
            <SitelineText variant="h4" color="grey70">
              {previewMenuLabel}
            </SitelineText>
          </Button>
          <Menu
            anchorEl={menuAnchorEl}
            keepMounted
            open={Boolean(menuAnchorEl)}
            onClose={handleClosePreviewMenu}
            transformOrigin={previewMenuTransformOrigin}
            anchorOrigin={previewMenuAnchorOrigin}
          >
            {filteredFormPreviewOptions.map((formTypeOption) => (
              <MenuItem
                key={formTypeOption}
                onClick={() => handleSelectFormPreviewType(formTypeOption)}
                selected={formPreviewType === formTypeOption}
              >
                {formTypeToPreviewMenuLabel(formTypeOption)}
              </MenuItem>
            ))}
          </Menu>
        </div>
      )}
    </PdfPreviewDialog>
  )
}
