import { DocumentNode } from 'graphql'
import _ from 'lodash'
import { useEffect } from 'react'
import {
  BillingType,
  FormTemplateAnnotationType,
  PhysicalSignatureRequired,
  SignatureAnnotationType,
  pdfTypes,
} from 'siteline-common-all'
import { ReadonlyDeep } from 'type-fest'
import { ContractForProjectHome } from '../../components/billing/home/ProjectHome'
import {
  ContractForProjectOnboarding,
  PayAppRequirementGroupForOnboarding,
} from '../../components/billing/onboarding/OnboardingTaskList'
import { ContractForProjectSettings } from '../../components/vendors/Vendors.lib'
import { SignatureInput } from '../components/Pdf/PdfViewer'
import {
  ContractForPayAppRequirementGroupsQuery,
  ContractForVendorsProjectHome,
  FormTemplateAnnotationValueInput,
  GetCompanyForOnboardingQuery,
} from '../graphql/apollo-operations'

export function numSignaturesMissing(
  signatureInput: SignatureInput,
  metadata: pdfTypes.PageMetadata[]
) {
  const requiredSignatureIds = _.flatten(
    _.map(metadata, ({ annotations }) => {
      return annotations
        .filter(
          (annotation) =>
            annotation.signatureType === SignatureAnnotationType.DIGITAL && !annotation.isOptional
        )
        .map((annotation) => annotation.id)
    })
  )

  return _.difference(requiredSignatureIds, signatureInput.signedAnnotationIds).length
}

/**
 * Return the number of missing user-entered fields. Missing signatures are detected separately.
 */
export function numRequiredUserEnteredFieldsMissing(
  formValuesInput: FormTemplateAnnotationValueInput[],
  metadata: pdfTypes.PageMetadata[]
) {
  const filledOutAnnotationIds = formValuesInput
    .filter((input) => input.value.length > 0)
    .map((input) => input.formTemplateAnnotationId)
  const allAnnotations = _.flatten(_.map(metadata, (metadata) => metadata.annotations))
  const requiredAnnotations = _.filter(allAnnotations, (annotation) => {
    return (
      annotation.type === FormTemplateAnnotationType.USER_ENTERED_FIELD && !annotation.isOptional
    )
  })
  const requiredAnnotationIds = _.map(requiredAnnotations, (annotation) => annotation.id)
  return _.difference(requiredAnnotationIds, filledOutAnnotationIds).length
}

type CompanyForPhysicalSignature = { notarySignature?: { id: string } | null }

/**
 * Given a list of page metadata, determine if a physical signature is required.
 * Note that if the signing company has a default notary signature, notary annotations will be ignored,
 * as long as the forms allow for it (useCompanyNotarySignatureIfAvailable = true)
 */
export function requiresPhysicalSignature(
  pages: pdfTypes.PageMetadata[],

  /**
   * Customer company signing the form.
   * For forms signed by the subcontractor, pass the subcontractor company.
   * For forms signed by a vendor, pass null.
   */
  company: CompanyForPhysicalSignature | null
): PhysicalSignatureRequired {
  for (const page of pages) {
    const annotations = page.annotations
    if (annotations.length === 0) {
      continue
    }
    const isMissingCompanyNotarySignature = !company || !company.notarySignature
    if (
      annotations.some(({ signatureType }) => signatureType === SignatureAnnotationType.NOTARY) &&
      (isMissingCompanyNotarySignature || !page.useCompanyNotarySignatureIfAvailable)
    ) {
      return PhysicalSignatureRequired.NOTARY_SIGNATURE_REQUIRED
    }
    if (
      annotations.some(({ signatureType }) => signatureType === SignatureAnnotationType.WITNESS)
    ) {
      return PhysicalSignatureRequired.WITNESS_SIGNATURE_REQUIRED
    }
    if (annotations.some(({ signatureType }) => signatureType === SignatureAnnotationType.WET)) {
      return PhysicalSignatureRequired.WET_SIGNATURE_REQUIRED
    }
  }
  return PhysicalSignatureRequired.NONE_REQUIRED
}

/**
 * Turns off autocomplete functionality for all browsers. We have to do it this way because Chrome
 * actively tries really hard to NOT get you to do this. Given that we have our own autocomplete
 * for emails, we don't want to double-pop the autocomplete box.
 */
export function useBrowserAutocompleteDisabled(formRef: React.RefObject<HTMLElement>) {
  useEffect(() => {
    const elements = formRef.current?.getElementsByTagName('input') || []
    // Using a basic for loop here because HTMLCollectionOf only supports traverse by index
    for (let elementIndex = 0; elementIndex < elements.length; elementIndex++) {
      const input = elements[elementIndex] as HTMLInputElement
      input.autocomplete = 'new-password'
    }
  }, [formRef])
}

export function createSignatureInput(signatureInput: SignatureInput) {
  const { signatureId, uploaded, digital } = signatureInput
  return {
    ...(signatureId && { signatureId }),
    ...(uploaded &&
      uploaded.file && {
        uploadSignatureInput: { signatureFile: uploaded.file },
      }),
    ...(digital && {
      digitalSignatureInput: {
        signatureName: digital.signature,
        signatureFont: digital.signatureFont,
      },
    }),
    signedAnnotationIds: signatureInput.signedAnnotationIds,
  }
}

export type CompanyWithForms = GetCompanyForOnboardingQuery['company']

export function deriveRequirementsFromContractPayApp(
  contract: ContractForProjectOnboarding,
  companyWithForms?: CompanyWithForms
) {
  if (!companyWithForms) {
    return []
  }
  const {
    defaultLumpSumRequirements,
    defaultUnitPriceRequirements,
    defaultTimeAndMaterialsRequirements,
  } = companyWithForms
  switch (contract.billingType) {
    case BillingType.LUMP_SUM:
      return defaultLumpSumRequirements ? [...defaultLumpSumRequirements] : []
    case BillingType.UNIT_PRICE:
      return defaultUnitPriceRequirements ? [...defaultUnitPriceRequirements] : []
    case BillingType.TIME_AND_MATERIALS:
      return defaultTimeAndMaterialsRequirements ? [...defaultTimeAndMaterialsRequirements] : []
    case BillingType.QUICK:
      return []
  }
}

export type VariantAndVersion = {
  variantId: string
  versionId: string
}

/**
 * Returns the latest version of a template
 */
export function getLatestVersion(
  template: ReadonlyDeep<VariantInput['template']>
): VariantInput['template']['versions'][number] | null {
  const latestVersion = _.maxBy(template.versions, (version) => version.versionNumber)
  return latestVersion ?? null
}

type VariantInput = {
  id: string
  template: {
    id: string
    versions: { id: string; versionNumber: number }[]
  }
}

/**
 * Given a variant, returns the IDs of the latest template version and the variant itself.
 */
export function resolveTemplateFromVariant(
  variant: ReadonlyDeep<VariantInput>
): VariantAndVersion | null {
  const latestVersion = getLatestVersion(variant.template)
  if (!latestVersion) {
    return null
  }
  return { versionId: latestVersion.id, variantId: variant.id }
}

type TemplateInput = {
  id: string
  versions: { id: string; versionNumber: number }[]
  variants: { id: string; isDefaultVariant: boolean }[]
}

/**
 * Given a template, returns the IDs of the latest version and the default variant.
 */
export function resolveTemplate(template: ReadonlyDeep<TemplateInput>): VariantAndVersion | null {
  const latestVersion = getLatestVersion(template)
  const defaultVariant = template.variants.find((variant) => variant.isDefaultVariant)
  if (!latestVersion || !defaultVariant) {
    return null
  }
  return { versionId: latestVersion.id, variantId: defaultVariant.id }
}

export function deriveDefaultTemplatesFromContractPayApp(
  payAppRequirements: PayAppRequirementGroupForOnboarding[]
): VariantAndVersion[] {
  return _.chain(payAppRequirements)
    .orderBy((requirement) => requirement.order)
    .flatMap((requirementGroup) =>
      _.chain(requirementGroup.payAppRequirements)
        .filter((requirement) => !!requirement.templateVariant?.template.isCustomerReady)
        .orderBy((requirement) => requirement.groupOrder)
        .flatMap((requirement) => {
          if (!requirement.templateVariant) {
            return null
          }
          return resolveTemplateFromVariant(requirement.templateVariant)
        })
        .value()
    )
    .compact()
    .value()
}

export function deriveTemplatesFromContractPayApp(
  contract: ContractForPayAppRequirementGroupsQuery['contractByProjectId']
): VariantAndVersion[] {
  if (!contract.payAppRequirementGroups.length) {
    return []
  }
  const templateIds = _.chain(contract.payAppRequirementGroups)
    .orderBy((requirementGroup) => requirementGroup.order)
    .flatMap((group) =>
      _.chain(group.payAppRequirements)
        .filter((requirement) => !!requirement.templateVariant?.template.isCustomerReady)
        .orderBy((requirement) => requirement.groupOrder)
        .flatMap((requirement) => {
          if (!requirement.templateVariant) {
            return null
          }
          return resolveTemplateFromVariant(requirement.templateVariant)
        })
        .value()
    )
    .compact()
    .value()

  return templateIds
}

export function getPayAppRequirementGroupForFormTemplateId(
  contract: ContractForPayAppRequirementGroupsQuery['contractByProjectId'],
  templateId: string
): PayAppRequirementGroupForOnboarding | undefined {
  return _.find(contract.payAppRequirementGroups, (group) => {
    return group.payAppRequirements.some(
      (requirement) =>
        requirement.templateVariant && requirement.templateVariant.template.id === templateId
    )
  })
}

export function derivePrimaryLienWaiverTemplatesFromContract(
  contract: ContractForProjectOnboarding
): VariantAndVersion[] {
  if (!contract.lienWaiverTemplates) {
    return []
  }
  return _.chain([
    contract.lienWaiverTemplates.conditionalFinalVariant,
    contract.lienWaiverTemplates.conditionalProgressVariant,
    contract.lienWaiverTemplates.unconditionalFinalVariant,
    contract.lienWaiverTemplates.unconditionalProgressVariant,
  ])
    .compact()
    .filter((variant) => variant.template.isCustomerReady)
    .map(resolveTemplateFromVariant)
    .compact()
    .value()
}

export function deriveDefaultPrimaryLienWaiverTemplatesFromContract(
  company?: CompanyWithForms
): VariantAndVersion[] {
  const defaultTemplateSet = company?.defaultPrimaryLienWaivers ?? null
  if (!defaultTemplateSet) {
    return []
  }
  return _.chain([
    defaultTemplateSet.conditionalFinalVariant,
    defaultTemplateSet.conditionalProgressVariant,
    defaultTemplateSet.unconditionalFinalVariant,
    defaultTemplateSet.unconditionalProgressVariant,
  ])
    .compact()
    .filter((variant) => variant.template.isCustomerReady)
    .map(resolveTemplateFromVariant)
    .compact()
    .value()
}

export function deriveVendorLienWaiverTemplatesFromContract(
  contract:
    | ContractForProjectOnboarding
    | ContractForVendorsProjectHome
    | ContractForProjectSettings
): VariantAndVersion[] {
  if (!contract.lowerTierLienWaiverTemplates) {
    return []
  }
  return _.chain([
    contract.lowerTierLienWaiverTemplates.conditionalFinalVariant,
    contract.lowerTierLienWaiverTemplates.conditionalProgressVariant,
    contract.lowerTierLienWaiverTemplates.unconditionalFinalVariant,
    contract.lowerTierLienWaiverTemplates.unconditionalProgressVariant,
  ])
    .compact()
    .filter((variant) => variant.template.isCustomerReady)
    .map(resolveTemplateFromVariant)
    .compact()
    .value()
}

export function deriveDefaultVendorLienWaiverTemplatesFromContract(
  company?: CompanyWithForms
): VariantAndVersion[] {
  const defaultTemplateSet = company?.defaultVendorLienWaivers ?? null
  if (!defaultTemplateSet) {
    return []
  }
  return _.chain([
    defaultTemplateSet.conditionalFinalVariant,
    defaultTemplateSet.conditionalProgressVariant,
    defaultTemplateSet.unconditionalFinalVariant,
    defaultTemplateSet.unconditionalProgressVariant,
  ])
    .compact()
    .filter((variant) => variant.template.isCustomerReady)
    .map(resolveTemplateFromVariant)
    .compact()
    .value()
}

export function deriveChangeOrderTemplateFromContract(
  contract: Pick<ContractForProjectOnboarding, 'changeOrderRequestTemplate'>
): VariantAndVersion | null {
  if (!contract.changeOrderRequestTemplate?.isCustomerReady) {
    return null
  }
  return resolveTemplate(contract.changeOrderRequestTemplate)
}

export function deriveDefaultChangeOrderTemplateFromContract(
  company?: CompanyWithForms
): VariantAndVersion | null {
  if (!company?.defaultChangeOrderRequestTemplate?.isCustomerReady) {
    return null
  }
  return resolveTemplate(company.defaultChangeOrderRequestTemplate)
}

export function deriveChangeOrderLogTemplateFromContract(
  contract: Pick<ContractForProjectOnboarding | ContractForProjectHome, 'changeOrderLogTemplate'>
): VariantAndVersion | null {
  if (!contract.changeOrderLogTemplate?.isCustomerReady) {
    return null
  }
  return resolveTemplate(contract.changeOrderLogTemplate)
}

export function deriveDefaultChangeOrderLogTemplateFromContract(
  company?: CompanyWithForms
): VariantAndVersion | null {
  if (!company?.defaultChangeOrderLogTemplate?.isCustomerReady) {
    return null
  }
  return resolveTemplate(company.defaultChangeOrderLogTemplate)
}

export type SelectFormsRefetchQuery = {
  refetchQueries: Array<
    | {
        query: DocumentNode
        variables: {
          input: { projectIds: string[]; companyId: string }
        }
      }
    | {
        query: DocumentNode
        variables: {
          input: { projectId: string; companyId: string }
        }
      }
  >
}
