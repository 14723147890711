import DragHandleIcon from '@mui/icons-material/DragHandle'
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing'
import { Button, IconButton, styled } from '@mui/material'
import { ReactNode } from '@tanstack/react-router'
import clsx from 'clsx'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import {
  colors,
  SitelineText,
  SitelineTooltip,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { SitelineDialog } from '../../../common/components/SitelineDialog'
import {
  ContractForPayAppRequirementGroupsQuery,
  useUpdatePayAppRequirementGroupsOrderMutation,
} from '../../../common/graphql/apollo-operations'
import { trackPayAppFormsReordered } from '../../../common/util/MetricsTracking'
import { PayAppRequirementGroupForOnboarding } from './OnboardingTaskList'

const StyledReorderPayAppFormsButton = styled('div')(() => ({
  color: colors.grey50,
  '& .icon': {
    color: colors.grey50,
  },
}))

const StyledReorderPayAppFormsDialog = styled(SitelineDialog)(({ theme }) => ({
  '& .dialogPopover': {
    overflow: 'hidden',
  },
  '& .icon': {
    color: colors.grey50,
  },
  '& .dragHandle': {
    cursor: 'grab',
  },
  '& .row': {
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${colors.grey30}`,
    },
    '&.isDragging': {
      borderTop: `1px solid ${colors.grey30}`,
      borderBottom: `1px solid ${colors.grey30}`,
    },
  },
}))

const i18nBase = 'projects.subcontractors.settings.forms'

type ContractForPayAppRequirementGroups =
  ContractForPayAppRequirementGroupsQuery['contractByProjectId']

type PayAppRequirementGroupWithName = PayAppRequirementGroupForOnboarding & {
  userVisibleName: string
}

interface ReorderPayAppFormsDialogButtonProps {
  contract: ContractForPayAppRequirementGroups
  className?: string
  location: string
  /** @default 'text' */
  buttonVariant?: 'text' | 'icon'
  disabledTooltip?: ReactNode
  onSubmitSuccess?: () => void
  payAppId?: string
}

/**
 * Given a set of pay app requirement groups, identifies their corresponding form template names. If
 * the forms exist, will render a button to open the dialog for reordering pay app requirement groups.
 * If pay app requirement groups do not exist, will return null.
 */
export function ReorderPayAppFormsDialogButton({
  contract,
  className,
  location,
  buttonVariant = 'text',
  disabledTooltip,
  onSubmitSuccess,
  payAppId,
}: ReorderPayAppFormsDialogButtonProps) {
  const { t } = useTranslation()
  const snackbar = useSitelineSnackbar()

  const initialGroups = useMemo(() => {
    return _.chain(contract.payAppRequirementGroups)
      .map((payAppRequirementGroup) => {
        const requirementNames = _.chain(payAppRequirementGroup.payAppRequirements)
          .map((requirement) => requirement.templateVariant?.template.userVisibleName ?? null)
          .compact()
          .value()
        if (requirementNames.length === 0) {
          return null
        }
        return {
          ...payAppRequirementGroup,
          userVisibleName: requirementNames.join(', '),
        }
      })
      .compact()
      .orderBy((group) => group.order)
      .value()
  }, [contract])

  const [groups, setGroups] = useState<PayAppRequirementGroupWithName[]>(initialGroups)
  const [reorderFormsDialogOpen, handleOpenReorderFormsDialog, handleCloseReorderFormsDialog] =
    useToggle()

  const [reorderPayAppRequirements] = useUpdatePayAppRequirementGroupsOrderMutation()

  const orderedGroups = useMemo(() => _.orderBy(groups, (group) => group.order, 'asc'), [groups])

  const handleCloseAndResetSortOrder = useCallback(() => {
    handleCloseReorderFormsDialog()
    setGroups(initialGroups)
  }, [initialGroups, handleCloseReorderFormsDialog])

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      // Only update if the item was dropped in a new position
      if (result.reason === 'DROP' && !!result.destination) {
        // Create an array with the updated order
        const newGroups = [...orderedGroups]
        const [removed] = newGroups.splice(result.source.index, 1)
        newGroups.splice(result.destination.index, 0, removed)

        // Update the order property for each group, based on the updated sorted array
        const updatedGroups = newGroups.map((group, index) => ({
          ...group,
          order: index,
        }))
        setGroups(updatedGroups)
      }
    },
    [orderedGroups]
  )

  const handleSubmit = useCallback(async () => {
    try {
      const sortedGroupIds = orderedGroups.map((group) => group.id)
      await reorderPayAppRequirements({
        variables: {
          input: {
            contractId: contract.id,
            groupIds: sortedGroupIds,
            payAppId,
          },
        },
      })
      if (onSubmitSuccess) {
        onSubmitSuccess()
      }
      trackPayAppFormsReordered({
        contractId: contract.id,
        projectName: contract.project.name,
        location,
      })
      handleCloseReorderFormsDialog()
      snackbar.showSuccess(t(`${i18nBase}.reordered_pay_app_forms`))
    } catch (error) {
      snackbar.showError(error.message)
    }
  }, [
    contract.id,
    contract.project.name,
    handleCloseReorderFormsDialog,
    location,
    onSubmitSuccess,
    orderedGroups,
    payAppId,
    reorderPayAppRequirements,
    snackbar,
    t,
  ])

  // Update groups whenever the contract changes
  useEffect(() => {
    setGroups(initialGroups)
  }, [initialGroups])

  if (orderedGroups.length <= 1) {
    return null
  }

  const isDisabled = !!disabledTooltip
  const tooltipTitle = disabledTooltip || t(`${i18nBase}.reorder_forms`)

  return (
    <>
      <StyledReorderPayAppFormsButton>
        {buttonVariant === 'text' && (
          <Button
            variant="text"
            color="secondary"
            onClick={handleOpenReorderFormsDialog}
            startIcon={<FormatLineSpacingIcon className="icon" />}
            className={className}
            disabled={isDisabled}
          >
            {t(`${i18nBase}.reorder_forms`)}
          </Button>
        )}
        {buttonVariant === 'icon' && (
          <SitelineTooltip title={tooltipTitle}>
            <div>
              <IconButton
                color="secondary"
                onClick={handleOpenReorderFormsDialog}
                className={className}
                disabled={isDisabled}
              >
                <FormatLineSpacingIcon className="icon" />
              </IconButton>
            </div>
          </SitelineTooltip>
        )}
      </StyledReorderPayAppFormsButton>
      <StyledReorderPayAppFormsDialog
        open={reorderFormsDialogOpen}
        title={t(`${i18nBase}.reorder_pay_app_forms_title`)}
        subtitle={t(`${i18nBase}.reorder_pay_app_forms_subtitle`)}
        onClose={handleCloseAndResetSortOrder}
        onSubmit={handleSubmit}
        popoverClassName="dialogPopover"
        size="email"
        submitLabel={t('common.actions.save')}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="payAppRequirementGroups">
            {(droppableProvided) => (
              <div {...droppableProvided.droppableProps} ref={droppableProvided.innerRef}>
                <>
                  {orderedGroups.map((group, index) => (
                    <Draggable key={group.id} draggableId={group.id} index={index}>
                      {(draggableProvided, snapshot) => (
                        <div
                          {...draggableProvided.draggableProps}
                          ref={draggableProvided.innerRef}
                          className={clsx('row', { isDragging: snapshot.isDragging })}
                        >
                          <div
                            {...draggableProvided.dragHandleProps}
                            onMouseDown={(evt) => evt.currentTarget.focus()}
                            className="dragHandle"
                          >
                            <DragHandleIcon className="icon" />
                          </div>
                          <SitelineText variant="body1">{group.userVisibleName}</SitelineText>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </StyledReorderPayAppFormsDialog>
    </>
  )
}
