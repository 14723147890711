import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import PendingActionsIcon from '@mui/icons-material/PendingActions'
import { Button, Collapse } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { useParams } from '@tanstack/react-router'
import clsx from 'clsx'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ButtonLabelSpinner,
  ProjectOnboardingFormType,
  SitelineText,
  colors,
  makeStylesFast,
  useSitelineSnackbar,
  useToggle,
} from 'siteline-common-web'
import { Row } from '../../../common/components/Row'
import { useSitelineConfirmation } from '../../../common/components/SitelineConfirmation'
import { Spacer } from '../../../common/components/Spacer'
import { useCompanyContext } from '../../../common/contexts/CompanyContext'
import { useProjectContext } from '../../../common/contexts/ProjectContext'
import { useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation } from '../../../common/graphql/apollo-operations'
import { SelectFormsRefetchQuery, VariantAndVersion } from '../../../common/util/Forms'
import {
  trackClearFormsButtonClicked,
  trackDeleteFormTemplateButtonClicked,
  trackIncludeChangeOrderLogOnPayAppsUpdated,
  trackSelectedProjectForms,
  trackUploadFormsButtonClicked,
  trackUploadMoreFormsButtonClicked,
} from '../../../common/util/MetricsTracking'
import {
  ContractOnboardingFormType,
  OnboardingFormsInstructions,
  REQUIRED_ONBOARDING_FORMS,
  useUploadOnboardingForms,
} from '../../../common/util/ProjectOnboarding'
import { PendingFile } from '../backup/attachments/FileDragUpload'
import { IncludeChangeOrderLogInPayAppToggle } from './IncludeChangeOrderLogInPayAppToggle'
import { InlineFormsPreview } from './InlineFormsPreview'
import { OnboardingFormUploadDialog } from './OnboardingFormUploadDialog'
import { ContractForProjectOnboarding } from './OnboardingTaskList'
import { ReorderPayAppFormsDialogButton } from './ReorderPayAppFormsDialogButton'
import { SelectFormTemplatesDialog } from './SelectFormTemplatesDialog'

const useStyles = makeStylesFast((theme: Theme) => ({
  root: {
    '&:not(:first-of-type)': {
      borderTop: `1px solid ${colors.grey20}`,
      padding: theme.spacing(2, 0),
    },
    '&:first-of-type': {
      paddingBottom: theme.spacing(2),
    },
    // Ensure all icons are the same size so that when they're swapped, we don't see
    // any layout shift (ButtonLabelSpinner has 4px left margin, which is why uploadIcon is 4px smaller)
    '& .icon': {
      fontSize: 20,
      height: 20,
      width: 20,
    },
    '& .uploadIcon': {
      fontSize: 16,
      height: 16,
      width: 16,
    },
    '& .processingFormsBanner': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1.5),
      backgroundColor: colors.grey10,
      border: `1px solid ${colors.grey20}`,
      borderRadius: 4,
      marginTop: theme.spacing(1.5),
      '& .processingFormsIcon': {
        color: colors.grey50,
        marginRight: theme.spacing(1),
      },
    },
    '& .processingAdditional': {
      marginTop: theme.spacing(1.5),
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .titleSection': {
      display: 'flex',
      alignItems: 'center',
      '& .title': {
        marginRight: theme.spacing(1),
      },
      '& .optional': {
        paddingTop: 3,
      },
    },
    '& .buttonsSection': {
      '& .formsButton': {
        color: colors.grey50,
      },
    },
  },
  preview: {
    marginTop: theme.spacing(1.5),
  },
}))

const i18nBase = 'projects.subcontractors.settings.forms'

interface OnboardingFormsSectionProps {
  onboardingFormType: ContractOnboardingFormType
  contract: ContractForProjectOnboarding
  selectFormsRefetchQuery?: SelectFormsRefetchQuery

  /** Form templates that have been onboarded and processed. Empty array if processing/incomplete */
  formTemplates: VariantAndVersion[]

  /** The default form templates for the company that the user is with (regardless of their form selection) */
  defaultFormTemplates: VariantAndVersion[]

  /** If true, will show a message in the default forms dialog explaining conditional forms */
  hasConditionalDefaultForms?: boolean

  /** If forms are already onboarded, a button to clear forms will be shown with this callback */
  onClearForms: () => Promise<void>

  /** Handles deleting a single form from a package (e.g. deleting g703 from the pay app package) */
  onDeleteForm?: (templateId: string) => Promise<void>

  /**
   * If completed but no form templates are provided, shows a processing forms message.
   * This is relevant to form uploads
   */
  isProcessingForms: boolean

  /**
   * Only applicable if this is rendered in a component that has an "edit" mode,
   * as is the case in project settings.
   * @default true
   */
  isEditing?: boolean

  /**
   * Indicates whether or not a confirmation modal should be used before deleting or clearing forms.
   * @default false
   */
  shouldConfirmFormDeletion?: boolean

  /** Pass in undefined if this section should NOT display the CO log toggle */
  includeChangeOrderLogInPayAppPackage?: boolean
}

/** The "upload forms" section for onboarding new forms, either by using default, copying or uploading */
export function OnboardingFormsSection({
  onboardingFormType,
  formTemplates,
  defaultFormTemplates,
  hasConditionalDefaultForms,
  onClearForms,
  isProcessingForms,
  contract,
  selectFormsRefetchQuery,
  onDeleteForm,
  isEditing = true,
  shouldConfirmFormDeletion = false,
  includeChangeOrderLogInPayAppPackage,
}: OnboardingFormsSectionProps) {
  const { t } = useTranslation()
  const { name: projectName, id: projectId } = useProjectContext()
  const { confirm } = useSitelineConfirmation()
  const classes = useStyles()
  const params = useParams({
    strict: false,
    select: (params) => ({ tab: params.tab }),
  })
  const snackbar = useSitelineSnackbar()
  const { companyId } = useCompanyContext()

  const [isSelectTemplatesDialogOpen, handleOpenSelectDialog, handleCloseSelectDialog] = useToggle()
  const [isUploadMoreDialogOpen, handleOpenUploadMore, handleCloseUploadMore] = useToggle()
  const [isClearingForms, setIsClearingForms] = useState<boolean>(false)
  const [isUploadingForms, setIsUploadingForms] = useState<boolean>(false)

  const [updateIncludeCoLogOnPayApps] = useSetIncludeChangeOrderLogIncludedInPayAppPackageMutation()

  const uploadFormTemplateDocuments = useUploadOnboardingForms({
    formType: onboardingFormType,
    contractId: contract.id,
  })

  const isOptional = useMemo(
    () => !REQUIRED_ONBOARDING_FORMS.includes(onboardingFormType),
    [onboardingFormType]
  )

  const sectionTitle = useMemo(() => {
    const formTypeToTitle: Record<ContractOnboardingFormType, string> = {
      [ProjectOnboardingFormType.PAY_APP]: t(`${i18nBase}.pay_app`),
      [ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER]: t(`${i18nBase}.primary_lien_waivers`),
      [ProjectOnboardingFormType.VENDOR_LIEN_WAIVER]: t(`${i18nBase}.vendor_lien_waivers`),
      [ProjectOnboardingFormType.CHANGE_ORDER_REQUEST]: t(`${i18nBase}.change_order_request`),
      [ProjectOnboardingFormType.CHANGE_ORDER_LOG]: t(`${i18nBase}.change_order_log`),
    }
    return formTypeToTitle[onboardingFormType]
  }, [onboardingFormType, t])

  const selectFormTemplateDialogTitle = useMemo(() => {
    const formTypeToTitle = {
      [ProjectOnboardingFormType.PAY_APP]: t(`${i18nBase}.select_pay_app_forms`),
      [ProjectOnboardingFormType.PRIMARY_LIEN_WAIVER]: t(
        `${i18nBase}.select_primary_lien_waiver_forms`
      ),
      [ProjectOnboardingFormType.VENDOR_LIEN_WAIVER]: t(
        `${i18nBase}.select_vendor_lien_waiver_forms`
      ),
      [ProjectOnboardingFormType.CHANGE_ORDER_REQUEST]: t(`${i18nBase}.select_change_order_forms`),
      [ProjectOnboardingFormType.CHANGE_ORDER_LOG]: t(`${i18nBase}.select_change_order_log_forms`),
    }
    return formTypeToTitle[onboardingFormType]
  }, [onboardingFormType, t])

  const handleClearForms = useCallback(async () => {
    setIsClearingForms(true)
    await onClearForms()
    trackClearFormsButtonClicked({
      contractId: contract.id,
      projectName,
      formsType: onboardingFormType,
      location: params.tab ?? '',
    })
    setIsClearingForms(false)
  }, [onClearForms, contract.id, projectName, onboardingFormType, params.tab])

  const handleDeleteForm = useCallback(
    async (formTemplateId: string) => {
      if (!onDeleteForm) {
        return
      }
      setIsClearingForms(true)
      await onDeleteForm(formTemplateId)
      trackDeleteFormTemplateButtonClicked({
        contractId: contract.id,
        projectName,
        formsType: onboardingFormType,
        location: params.tab ?? '',
      })
      setIsClearingForms(false)
    },
    [onDeleteForm, contract.id, projectName, onboardingFormType, params.tab]
  )

  const handleConfirmAndClearForms = useCallback(async () => {
    if (shouldConfirmFormDeletion) {
      confirm({
        title: t(`${i18nBase}.confirm_clear_title`),
        details: t(`${i18nBase}.confirm_remove_template_details`),
        confirmationType: 'delete',
        callback: async (confirmed) => {
          if (confirmed) {
            await handleClearForms()
          }
        },
      })
    } else {
      await handleClearForms()
    }
  }, [confirm, handleClearForms, shouldConfirmFormDeletion, t])

  const handleConfirmAndDeleteForm = useCallback(
    async (formTemplateId: string) => {
      if (!onDeleteForm) {
        return
      }
      if (shouldConfirmFormDeletion) {
        confirm({
          title: t(`${i18nBase}.confirm_remove_template_title`),
          details: t(`${i18nBase}.confirm_remove_template_details`),
          confirmationType: 'delete',
          callback: async (confirmed) => {
            if (confirmed) {
              await handleDeleteForm(formTemplateId)
            }
          },
        })
      } else {
        await handleDeleteForm(formTemplateId)
      }
    },
    [confirm, handleDeleteForm, onDeleteForm, shouldConfirmFormDeletion, t]
  )

  const handleSelectFormsClick = useCallback(() => {
    handleOpenSelectDialog()
    trackUploadFormsButtonClicked({
      contractId: contract.id,
      projectName,
      formsType: onboardingFormType,
      location: params.tab ?? '',
    })
  }, [contract.id, handleOpenSelectDialog, onboardingFormType, params.tab, projectName])

  const handleUploadMoreFormsClick = useCallback(() => {
    handleOpenUploadMore()
    trackUploadMoreFormsButtonClicked({
      contractId: contract.id,
      projectName,
      formsType: onboardingFormType,
      location: params.tab ?? '',
    })
  }, [contract.id, handleOpenUploadMore, onboardingFormType, params.tab, projectName])

  const handleUploadForms = useCallback(
    async (
      pendingFiles: PendingFile[],
      instructions: OnboardingFormsInstructions,
      includeChangeOrderLogOnPayApps?: boolean
    ) => {
      setIsUploadingForms(true)
      try {
        await uploadFormTemplateDocuments(
          pendingFiles,
          instructions,
          includeChangeOrderLogOnPayApps
        )
        trackSelectedProjectForms({
          contractId: contract.id,
          projectName,
          formsType: onboardingFormType,
          selectType: 'newForms',
          location: params.tab ?? '',
          includeChangeOrderLogOnPayApps,
        })
        snackbar.showSuccess(t(`${i18nBase}.forms_uploaded`))
      } catch (err) {
        snackbar.showError(err.message)
        // Re-throw so that the dialog doesn't close
        throw err
      } finally {
        setIsUploadingForms(false)
      }
    },
    [
      contract.id,
      onboardingFormType,
      params.tab,
      projectName,
      snackbar,
      t,
      uploadFormTemplateDocuments,
    ]
  )

  const handleToggleIncludeCoLogOnPayApps = useCallback(
    async (shouldInclude: boolean) => {
      try {
        await updateIncludeCoLogOnPayApps({
          variables: {
            input: {
              contractId: contract.id,
              includeChangeOrderLogOnPayApps: shouldInclude,
            },
          },
        })
        trackIncludeChangeOrderLogOnPayAppsUpdated({
          companyId,
          projectId,
          projectName,
          shouldInclude,
        })
        snackbar.showSuccess(
          shouldInclude
            ? t(`${i18nBase}.include_co_log_toggled_on`)
            : t(`${i18nBase}.include_co_log_toggled_off`)
        )
      } catch (error) {
        snackbar.showError(error.message)
      }
    },
    [companyId, contract.id, projectId, projectName, snackbar, t, updateIncludeCoLogOnPayApps]
  )

  const hasSelectedForms = formTemplates.length > 0
  const shouldDisplayCoLogToggle = includeChangeOrderLogInPayAppPackage !== undefined
  const isCoLogToggleVisible = isEditing || hasSelectedForms || isProcessingForms
  const isCoLogToggleDisabled = !isEditing || (!isProcessingForms && !hasSelectedForms)
  const isPayAppFormsSection = onboardingFormType === ProjectOnboardingFormType.PAY_APP
  const shouldIncludeReorderButton = isPayAppFormsSection && isEditing

  const processingFormsMessage = hasSelectedForms
    ? t(`${i18nBase}.processing_additional_forms`)
    : t(`${i18nBase}.processing_forms`)

  return (
    <div className={classes.root}>
      <div className={classes.actions}>
        <div className="titleSection">
          <SitelineText variant="h4" color="grey70" className="title">
            {sectionTitle}
          </SitelineText>
          {isOptional && (
            <SitelineText variant="smallText" color="grey50" className="optional">
              {t(`${i18nBase}.optional`)}
            </SitelineText>
          )}
        </div>
        <Row className="buttonsSection" alignItems="center" gap={32}>
          {!hasSelectedForms && !isProcessingForms && isEditing && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleSelectFormsClick}
              startIcon={
                isUploadingForms ? <ButtonLabelSpinner className="uploadIcon" /> : undefined
              }
            >
              {t(`${i18nBase}.select_forms`)}
            </Button>
          )}
          {shouldIncludeReorderButton && (
            <ReorderPayAppFormsDialogButton
              contract={contract}
              className="formsButton"
              location={params.tab ?? ''}
            />
          )}
          {(hasSelectedForms || isProcessingForms) && isEditing && (
            <>
              <Button
                variant="text"
                color="secondary"
                className="formsButton"
                onClick={handleUploadMoreFormsClick}
                startIcon={
                  isUploadingForms ? (
                    <ButtonLabelSpinner className="uploadIcon" />
                  ) : (
                    <AddIcon className="icon" />
                  )
                }
              >
                {t(`${i18nBase}.upload_new`)}
              </Button>
              <Button
                variant="text"
                color="secondary"
                className="formsButton"
                startIcon={
                  isClearingForms ? (
                    <ButtonLabelSpinner className="uploadIcon" />
                  ) : (
                    <CloseIcon className="icon" />
                  )
                }
                onClick={handleConfirmAndClearForms}
              >
                {t(`${i18nBase}.clear`)}
              </Button>
            </>
          )}
        </Row>
      </div>
      <Collapse in={hasSelectedForms}>
        <div className={classes.preview}>
          <InlineFormsPreview
            formTemplates={formTemplates}
            onDeleteForm={onDeleteForm ? handleConfirmAndDeleteForm : undefined}
            disableRemove={isClearingForms}
          />
        </div>
      </Collapse>
      <Collapse in={!hasSelectedForms}>
        <SitelineText variant="body2" color="grey50">
          {/* Return an empty string if transitioning between editing and !editing to prevent motion */}
          {isEditing ? '' : t(`${i18nBase}.no_forms_selected`)}
        </SitelineText>
      </Collapse>
      <Collapse
        unmountOnExit
        className={clsx({ processingAdditional: hasSelectedForms })}
        in={isProcessingForms}
      >
        <div className="processingFormsBanner">
          <PendingActionsIcon className="processingFormsIcon" />
          <SitelineText className="processingFormsText" variant="body2" color="grey70">
            {processingFormsMessage}
          </SitelineText>
        </div>
      </Collapse>
      {shouldDisplayCoLogToggle && (
        <>
          <Spacer minHeight={8} maxHeight={8} />
          <Collapse in={isCoLogToggleVisible}>
            <IncludeChangeOrderLogInPayAppToggle
              shouldInclude={includeChangeOrderLogInPayAppPackage}
              onChange={handleToggleIncludeCoLogOnPayApps}
              disabled={isCoLogToggleDisabled}
              shouldShowDisabledTooltip={isCoLogToggleDisabled && isEditing}
            />
          </Collapse>
        </>
      )}
      <SelectFormTemplatesDialog
        selectTemplateTypeTitle={selectFormTemplateDialogTitle}
        open={isSelectTemplatesDialogOpen}
        onClose={handleCloseSelectDialog}
        selectFormsRefetchQuery={selectFormsRefetchQuery}
        formType={onboardingFormType}
        defaultFormTemplates={defaultFormTemplates}
        hasConditionalDefaultForms={hasConditionalDefaultForms}
        contractId={contract.id}
        location={params.tab ?? ''}
      />
      <OnboardingFormUploadDialog
        onUploadForms={handleUploadForms}
        open={isUploadMoreDialogOpen}
        onClose={handleCloseUploadMore}
        formsType={onboardingFormType}
        submitting={isUploadingForms}
        includeChangeOrderLogInPayAppPackage={!!contract.includeChangeOrderLogInPayAppPackage}
      />
    </div>
  )
}
